import React from "react";
import Link from "../navigation/Link";
import Image from "../display/Image";
import { H3, P } from "../display";
import Card from "../layout/Card";
import { Box } from "../layout";

interface Props {
  fluidImage: any;
  title: string;
  excerpt: string;
  to: string;
  alt: string;
}

function PostCard({ fluidImage, alt, title, excerpt, to }: Props) {
  return (
    <Card sx={{ p: 0, pb: 2, mb: 3, bg: "background" }}>
      {fluidImage && (
        <Link to={to} sx={{ pl: 2, pr: 2, pt: 2 }}>
          <Image
            alt={alt}
            fluid={fluidImage}
            sx={{ mb: 0, maxHeight: "180px" }}
          />
        </Link>
      )}
      <Box sx={{ p: 3, mt: 1 }}>
        <Link to={to}>
          <H3 sx={{ mt: 0 }}>{title}</H3>
        </Link>
        <P>{excerpt}</P>
        <Link to={to}>Leer mas</Link>
      </Box>
    </Card>
  );
}

export default PostCard;
